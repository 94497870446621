ul {
  list-style: none; /* remove default list-style */
  padding-left: 0; /* remove default padding */
  text-align: center;

  margin: 0 auto;
  margin-top: 15px;
  padding: 0;
  border: 2px solid #ccc;
  border-radius: 5px;
  width: 50%;
  box-shadow: inset;
}

li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  font-size: 45px;
}

.scholarship {
  padding: 10px;
  font-size: 45px;
}

li:before {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: blue;
  color: white;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  left: -30px;
  top: 0;
  font-weight: bold;
}

li:last-child {
  border-bottom: none;
}