body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #febd11
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
  /* margin: 20px; */
  
}
.home-info {
  background-color: #005daa;
  padding: 0px 20px 20px 20px;
  text-align: center;
}
.home-info h1, .home-info h2 {
  color: #febd11
}
.home-info body2 {
  color: white;
  font-weight: bold;
}
.news-list{
  width: 75%;
}
.contact-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contact-items {  
  flex: 0 1 24%;
}
.membership-items {
  flex: 0 1 30%;
}
@media screen and (max-width: 962px){
  body {
    margin-left: 0px;
  }
  .menuBar {
    /* padding-left: 15px; */
    align-items: flex-start;
    width: '100%';
    display: 'flex';
    justify-content: 'space-between';
  }
  .content {
    /* background-color: #FFF; */
    padding: 0px;
    margin: 0px;
  }
  .news-list{
    width: 100%;
  }
  .contact-items {
    flex: 0 1 100%;
  }
  .membership-items {
    flex: 0 1 100%;
  }
}
.footer {
  background-color: #005daa;
  text-align: center;
  color: #febd11;
  font-weight: bold;
  
  padding: 10px;
}
.membership_form {
  margin: 5px;
}
/************************************************************ Start Flexbox Config  *************************************************************/
.flexbox-container{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: stretch;

  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-content: flex-end;
  align-content: flex-end;
  min-height: 100px;
  background-color: #febd11;
}

.maxitem {
  /* This will always take one line. */
  -webkit-flex: 0 1 100%;
  flex: 0 1 100%;
  /* margin: 10px 5px; */
  position: relative;  
}

.bigitem {
  /* This will be twice as big as the small item. */
  -webkit-flex: 2 60%;
  flex: 2 60%;
  margin: 10px 5px;
  /* align-self: stretch; */
  /* align-self: flex-start; */
  position: relative;
  border: 1px
}

.mediumitem{  
  -webkit-flex: 1 40%;
  flex: 1 40%;
  margin: 10px 5px;
  /* align-self: stretch; */
  /* align-self: flex-start;   */
  position: relative;
}

.smallitem {
  -webkit-flex: 1 30%;
  flex: 1 30%;
  margin: 10px 5px;
  /* align-self: stretch; */
  /* align-self: flex-start;   */
  position: relative;
}

.xsmallitem {
  -webkit-flex: 1 15%;
  flex: 1 15%;
  margin: 10px 5px;
  /* align-self: flex-start; */
  /* align-self: stretch; */
  position: relative;
}

@media screen and (max-width: 768px) {
  .smallitem,
  .bigitem,
  .xsmallitem,
  .mediumitem {
    -webkit-flex: 0 1 100%;
    flex: 0 1 100%;
  }
}

.item-panel {
  padding: 10px;
  border: 1px solid rgb(207, 207, 207);
  border-image: none;
  background-color: white;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
  overflow: hidden;
}

/************************************************************* End Flexbox Config  **************************************************************/

